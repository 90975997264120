<template>
  <b-modal
    id="domain-renew-modal"
    ref="domain-renew-modal"
    title="Domain Renewal"
    centered
    hide-header-close
    size="lg"
    no-close-on-esc
    no-close-on-backdrop
  >
    <ct-centered-spinner v-if="!renewPrice || loading" />
    <div v-else>
      <div v-html="modalText" />
      <select-payment-method
        v-if="showPaymentSelect"
        :is-billing-active="isBillingActive"
        @closeSection="closeSection"
        @togglePanel="togglePanel"
      />
    </div>
    <template v-slot:modal-footer>
      <b-button variant="default" aria-label="cancel button" @click="cancel">
        Cancel
      </b-button>
      <b-button :disabled="disableRenew" variant="primary" aria-label="delete button" @click="renew">
        Renew
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import SelectPaymentMethod from '@/components/shared/PaymentModals/SelectPaymentMethod.vue'

export default {
  name: 'DomainRenewModal',
  components: { SelectPaymentMethod, CtCenteredSpinner },
  props: {
    renewPrice: null,
    loading: {
      type: Boolean,
      default: false,
    },
    showPaymentSelect: {
      type: Boolean,
      default: false,
    },
    selectedPaymentMethod: {
      type: Object,
      default: null,
    },
    autoPayId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isBillingActive: false,
      hideSubmitRequest: false,
    }
  },
  computed: {
    modalText() {
      return `<p><strong>Do you wish to renew this domain for 1 year for $${this.renewPrice}?</strong><br/>`
    },
    disableRenew() {
      return !this.renewPrice || this.loading || (!this.selectedPaymentMethod && !this.autoPayId) || this.hideSubmitRequest
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel')
      this.closeSection()
    },
    renew() {
      this.$emit('renew')
      this.closeSection()
    },
    closeSection() {
      this.isBillingActive = false
      this.hideSubmitRequest = false
    },
    togglePanel() {
      this.isBillingActive = true
      this.hideSubmitRequest = true
    },
  },
}
</script>
