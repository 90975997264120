<template>
  <b-modal
    id="domain-lock-modal"
    ref="domain-lock-modal"
    title="Domain Locking"
    centered
    hide-header-close
    size="md"
  >
    <div v-html="modalText" />
    <template v-slot:modal-footer>
      <b-button variant="default" aria-label="cancel button" @click="$emit('cancel')">
        Cancel
      </b-button>
      <b-button variant="primary" aria-label="ok button" @click="$emit('ok')">
        {{ domainAction }} Domain
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import * as DOMPurify from 'dompurify'

export default {
  name: 'DomainLockModal',
  props: {
    selectedDomain: null,
  },
  data() {
    return {}
  },
  computed: {
    domainAction() {
      return this.selectedDomain?.locked ?
        'Unlock' :
        'Lock'
    },
    modalText() {
      const text = this.selectedDomain?.locked ?
        '<p><strong>You are about to unlock this domain.</strong> This will allow your domain to be transferred away from your account with us, your current registrar.</p>' +
        '<p><em>We recommend unlocking your domain only in the event that you need to make changes to your domain name servers.</em></p>' :
        '<p><strong>You are about to lock this domain.</strong> This will prevent unauthorized attempts to transfer your domain to another registrar.</p>'
      return DOMPurify.sanitize(text, {
        ALLOWED_TAGS: ['p', 'strong', 'em'],
      })
    },
  },
}
</script>
