var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "domain-renew-modal",
      attrs: {
        id: "domain-renew-modal",
        title: "Domain Renewal",
        centered: "",
        "hide-header-close": "",
        size: "lg",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "default", "aria-label": "cancel button" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: _vm.disableRenew,
                    variant: "primary",
                    "aria-label": "delete button",
                  },
                  on: { click: _vm.renew },
                },
                [_vm._v("\n      Renew\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      !_vm.renewPrice || _vm.loading
        ? _c("ct-centered-spinner")
        : _c(
            "div",
            [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.modalText) } }),
              _vm.showPaymentSelect
                ? _c("select-payment-method", {
                    attrs: { "is-billing-active": _vm.isBillingActive },
                    on: {
                      closeSection: _vm.closeSection,
                      togglePanel: _vm.togglePanel,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }